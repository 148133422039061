import { api, currentCreche } from '../api'

const AttendanceRepository = {
    getAll (query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/attendances`, query)
    },

    reporting (query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/attendances/reporting`, query)
    },

    calendar (query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/attendances/calendar`, query)
    },

    print (query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/attendances/print`, query, { responseType: 'blob' })
    },

    downloadExcel (payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/attendances/export`, payload, { responseType: 'blob' })
    },

    batch (payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/attendances/batch`, payload)
    },

    index (registrationUuid, query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/attendances`, query)
    },

    create (registrationUuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/attendances`, payload)
    },

    createByPeriod (registrationUuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/attendances/period`, payload)
    },

    update (registrationUuid, attendanceUuid, payload, crecheUuid = null) {
        return api().$put(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/attendances/${attendanceUuid}`, payload)
    },

    reservations (registrationUuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/reservations`, payload)
    },

    reservationMonth (registrationUuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/reservations/month`, payload)
    },

    attendanceMonth (registrationUuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/attendances/month`, payload)
    },

    delete (registrationUuid, uuid, crecheUuid = null) {
        return api().$delete(`/${crecheUuid || currentCreche().uuid}/registrations/${registrationUuid}/attendances/${uuid}`)
    },

    sendSnappiesReport (payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/reports/attendances/snappies`, payload)
    },

    bulkLeftAt (payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/attendances/batch-departure`, payload)
    }
}

export default AttendanceRepository
